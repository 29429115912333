import 'bootstrap';
import 'jquery-drawer';
import 'bootstrap';

$(function(){
    $('.link_btn a').on('click',function() { /* ①クリックアクションを設定 */
        var speed = 500; /* ②スクロールの速さを指定 */
        var href= $(this).attr('href'); /* ③クリックするリンクの位置の値を取得 */
        var target = $(href == "#" || href == "" ? 'html' : href); /* ④スクロール先を取得 */
        var position = target.offset().top; /* ⑤ページのトップからスクロール先までの位置を数値として取得 */
        $('body,html').animate({scrollTop: position}, speed, 'swing'); /* ⑥スクロールのアニメーション設定 */
        return false; /* ⑦falseを返し、URLに影響を与えないようにする */
    });
    $ ('.sendmail'). on ('click', function (e) {
        e.preventDefault (); //画面が更新されないように
        $('.send_check').slideUp();
        var error_flg = false;
        if(!$('.get_name').val()){
            error_flg = true;
            $('.error_name').slideDown();
        }else{
            $('.error_name').slideUp();
        }
        if(!$('.get_email').val()){
            error_flg = true;
            $('.error_email').slideDown();
        }else if(!$('.get_email').val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
            error_flg = true;
            $('.error_email').slideDown();
        }else{
            $('.error_email').slideUp();
        }
        if(!$('.get_tel').val()){
            error_flg = true;
            $('.error_tel').slideDown();
        }else if(!$('.get_tel').val().match(/^0{1}\d{9,10}$/)){
            error_flg = true;
            $('.error_tel').slideDown();
        }else{
            $('.error_tel').slideUp();
        }
        if(error_flg == true){
            return false;
        }else{
            $('.get_token').val('bbb');
        }
      
      // Ajax処理
      $.ajax ({
        type: 'post',
        url: 'send-mail.php',
        datatype: 'json',
        data: {
           name: $('.get_name').val(),   // 名前
           email: $('.get_email').val(),  // メールアドレス
           tel: $('.get_tel').val(), // 用件
           token: $('.get_token').val(), // 用件
           comment: $('.get_comment').val()  // 用件
        }
      }).done(function (data) {  // ajax通信が成功したら
            data = JSON.parse(data);
            if (data.result) {
                // メール「送信」に成功した時の処理
                // 画面にメッセージを表示、画面をリロードなど。
                $('.get_name').val('');
                $('.get_email').val('');
                $('.get_tel').val('');
                $('.get_comment').val('');
                $('.get_token').val('aaa');
                $('.send_check').addClass('ok').text('送信しました。').slideDown();
            } else {
                //メール送信に「失敗」した時の処理
                // 画面にメッセージを表示など
                $('.send_check').addClass('false').text('送信は失敗しました。').slideDown();
            }
        });
    });
});